const getSelectButtonStyles = (theme) => ({
    default: {
        padding: `${theme.spacing_sizes.xs * 1.75}px ${theme.spacing_sizes.l}px`,
        borderRadius: 6,
        backgroundColor: '#F8FAFF',
        backgroundColorSelected: '#F8FAFF',
        backgroundColorActive: '#F8FAFF',
        backgroundColorActiveHover: '#F8FAFF',
        opacityDisabled: 0.5,
        backgroundColorHover: theme.customColors.shades.primaryLight,
        fontSize: 16,
        userSelect: 'none',
        color: theme.palette.text.primary,
        colorSelected: theme.palette.text.primary,
        border: `1px solid ${theme.customColors.button.pill.border}`,
        borderSelected: `1px solid ${theme.palette.primary.main}`,
        borderColorHover: theme.palette.primary.main,
        borderError: `1px solid ${theme.palette.error.main}`,
        borderDisabled: `1px solid ${theme.customColors.button.pill.border}`,
        outline: '1px solid transparent',
        outlineActive: `1px solid ${theme.palette.primary.main}`,
        outlineDisabled: '1px solid transparent',
        boxShadow: '0px 0px 0px 3px transparent',
        boxShadowSelected: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
        boxShadowSelectedActive: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
        boxShadowActive: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
        boxShadowError: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
        boxShadowDisabled: '0px 0px 0px 3px transparent',
    },
    'with-checkox': {
        padding: `${theme.spacing_sizes.xs * 1.75}px ${theme.spacing_sizes.l}px`,
        border: `1px solid ${theme.customColors.button.pill.border}`,
        color: theme.palette.text.primary,
        colorSelected: theme.palette.text.primary,
        opacityDisabled: 0.5,
        borderColorHover: theme.customColors.pagination.regular,
        borderSelected: `1px solid ${theme.palette.primary.light}`,
        borderRadius: 6,
        backgroundColor: '#F8F9FA',
        backgroundColorSelected: '#F8FAFF',
        backgroundColorHover: theme.customColors.input.backgroundDisabled,
        backgroundColorActiveHover: theme.palette.primary.dark,
        backgroundColorActive: '#F8FAFF',
        outline: 'transparent',
        outlineActive: `1px solid ${theme.palette.primary.main}`,
        boxShadow: 'unset',
        boxShadowActive: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
        boxShadowSelectedActive: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
        endElementPadding: '',
    },
});
const defineBoxShadowStyles = ({ theme, hasError, selected, itemVariant, active, }) => {
    if (hasError) {
        return getSelectButtonStyles(theme)[itemVariant].boxShadowError;
    }
    if (active) {
        return selected ? getSelectButtonStyles(theme)[itemVariant].boxShadowSelectedActive :
            getSelectButtonStyles(theme)[itemVariant].boxShadowActive;
    }
    return selected ? getSelectButtonStyles(theme)[itemVariant].boxShadowSelected :
        getSelectButtonStyles(theme)[itemVariant].boxShadow;
};
const defineOutline = ({ theme, selected, active, itemVariant, hasError, }) => {
    if (hasError) {
        return getSelectButtonStyles(theme)[itemVariant].outline;
    }
    if (selected) {
        return getSelectButtonStyles(theme)[itemVariant].outline;
    }
    return active ? getSelectButtonStyles(theme)[itemVariant].outlineActive :
        getSelectButtonStyles(theme)[itemVariant].outline;
};
const defineBorderColor = ({ theme, hasError, selected, itemVariant, }) => {
    if (hasError) {
        return getSelectButtonStyles(theme)[itemVariant].borderError;
    }
    return selected ? getSelectButtonStyles(theme)[itemVariant].borderSelected :
        getSelectButtonStyles(theme)[itemVariant].border;
};
export { getSelectButtonStyles, defineBoxShadowStyles, defineBorderColor, defineOutline, };
