const defineInitValues = (options, value) => {
    const resultState = {};
    options.forEach((option) => {
        resultState[option.value] = option;
    });
    if (typeof value === 'string' && resultState[value]) {
        resultState[value].selected = true;
    }
    else if (typeof value === 'object') {
        value.forEach((key) => {
            resultState[key] = Object.assign(Object.assign({}, resultState[key]), { selected: true });
        });
    }
    return resultState;
};
const defineComponentValue = (options, value) => {
    const resultState = Object.assign({}, options);
    if (typeof value === 'string') {
        if (value.length) {
            Object.keys(options).forEach((key) => {
                if (key === value) {
                    resultState[key] = Object.assign(Object.assign({}, options[key]), { selected: true });
                }
                else {
                    resultState[key] = Object.assign(Object.assign({}, options[key]), { selected: false });
                }
            });
            return resultState;
        }
    }
    else if (typeof value === 'object') {
        if (value.length) {
            value.forEach((optionValue) => {
                if (resultState[optionValue]) {
                    resultState[optionValue] = Object.assign(Object.assign({}, resultState[optionValue]), { selected: true });
                }
                else {
                    resultState[optionValue] = Object.assign(Object.assign({}, options[optionValue]), { selected: false });
                }
            });
            return resultState;
        }
        Object.keys(options).forEach((optionKey) => {
            resultState[optionKey] = Object.assign(Object.assign({}, options[optionKey]), { selected: false });
        });
        return resultState;
    }
    return resultState;
};
const updateOptionsState = (prevOptions, value, multiple) => {
    const selectedOptionsValues = [];
    const newState = Object.assign({}, prevOptions);
    Object.keys(newState).forEach((optionKey) => {
        var _a, _b, _c, _d;
        if (newState[optionKey]) {
            if (multiple) {
                if (optionKey === value && !newState[optionKey].selected) {
                    selectedOptionsValues.push(optionKey);
                    newState[optionKey] = Object.assign(Object.assign({}, newState[optionKey]), { selected: true });
                }
                else if (optionKey === value && newState[optionKey].selected) {
                    newState[optionKey] = Object.assign(Object.assign({}, newState[optionKey]), { selected: false });
                }
                else if (newState[optionKey].selected) {
                    selectedOptionsValues.push(optionKey);
                }
            }
            else if (((_a = newState[optionKey]) === null || _a === void 0 ? void 0 : _a.value) === value && !((_b = newState[optionKey]) === null || _b === void 0 ? void 0 : _b.selected)) {
                newState[optionKey] = Object.assign(Object.assign({}, newState[optionKey]), { selected: true });
                selectedOptionsValues.push(optionKey);
            }
            else if (((_c = newState[optionKey]) === null || _c === void 0 ? void 0 : _c.value) !== value && ((_d = newState[optionKey]) === null || _d === void 0 ? void 0 : _d.selected)) {
                newState[optionKey] = Object.assign(Object.assign({}, newState[optionKey]), { selected: false });
            }
            else {
                newState[optionKey] = Object.assign({}, newState[optionKey]);
            }
        }
    });
    return { selectedOptionsValues, newOptions: newState };
};
export { defineComponentValue, updateOptionsState, defineInitValues };
