import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as XMarkIconComponent } from '@icons/wolfkit-light/xmark-light.svg';
import ButtonComponent from '@shared/ui/buttons/ButtonCustom';
const Button = styled(ButtonComponent, { shouldForwardProp: propName => propName !== 'iconPadding' })(props => ({
    color: props.theme.palette.text.primary,
    border: 'none',
    transition: 'none',
    padding: props.iconPadding,
    minWidth: 'unset',
    minHeight: 'unset',
    '&:hover': {
        color: props.theme.palette.text.primary,
        backgroundColor: '#F8F9FA',
        transition: 'none',
        border: 'none',
    },
    '&:active': {
        color: props.theme.palette.text.primary,
        backgroundColor: props.theme.customColors.button.pill.border,
        transition: 'none',
        border: 'none',
    },
    '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: 0,
    },
}));
const XMarkIcon = styled(XMarkIconComponent, { shouldForwardProp: propName => propName !== 'disabled' && propName !== 'width' && propName !== 'height' })(props => ({
    width: props.width,
    height: props.height,
    path: {
        fill: props.disabled ? '#D6DCE0' : props.theme.palette.text.primary,
    },
}));
const ResetButton = ({ onClick = undefined, disabled = undefined, iconHeight = undefined, iconWidth = undefined, size = undefined, children = undefined, iconPadding = 3, }) => (_jsx(Button, { onClick: onClick, size: size, disabled: disabled, iconPadding: iconPadding, startIcon: (_jsx(XMarkIcon, { disabled: disabled, height: iconHeight, width: iconWidth })), children: children }));
export default ResetButton;
