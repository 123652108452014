var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Pie from '@visx/shape/lib/shapes/Pie';
import { Group } from '@visx/group';
import AnimatedPieArc from '../AnimatedPieArc';
const ArcThickness = 10;
const ArcThicknessDelta = 10;
const DefaultMargin = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
};
// eslint-disable-next-line react/function-component-definition
function AnimatedPie(_a) {
    var { width, height, getKey, getColor, activeArc = undefined, onArcClick = () => { }, onArcHover = () => { }, arcThickness = ArcThickness, margin = DefaultMargin, disabled = false } = _a, props = __rest(_a, ["width", "height", "getKey", "getColor", "activeArc", "onArcClick", "onArcHover", "arcThickness", "margin", "disabled"]);
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const radius = Math.min(innerWidth, innerHeight) / 2;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;
    const getColorWrapper = (arc) => getColor(arc.data);
    const onClick = (arc) => {
        onArcClick(arc.data);
    };
    const onHover = (arc, isHovered) => {
        onArcHover(arc.data, isHovered);
    };
    return (_jsx("svg", { width: width, height: height, children: _jsx(Group, { top: centerY + margin.top, left: centerX + margin.left, children: _jsx(Pie, Object.assign({ innerRadius: radius - arcThickness, outerRadius: radius }, props, { children: (pie) => (pie.arcs.map(arc => (_jsx("g", { children: _jsx(AnimatedPieArc, { path: pie.path, arc: arc, arcThicknessDelta: ArcThicknessDelta, animate: (!!activeArc &&
                            getKey(activeArc) === getKey(arc.data)), getColor: getColorWrapper, onClick: onClick, onHover: onHover, disabled: disabled }) }, getKey(arc.data))))) })) }) }));
}
export default AnimatedPie;
