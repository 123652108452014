import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import styled from '@emotion/styled';
import { animated, useSpring } from '@react-spring/web';
import { useTheme } from '@mui/material';
import { BodySemiBold } from '@components/styled/Typography';
import { BOX_SHADOW_COLOR_DEFAULT, BOX_SHADOW_COLOR_SELECTED, BOX_SHADOW_SIZE, } from './styles';
const Button = animated(styled.button((props) => (Object.assign({ position: 'relative', flex: 1, width: 'auto', height: 'auto', alignItems: 'center', justifyContent: 'center', background: 'none', border: 'none', font: 'inherit', outline: 'inherit', padding: `${props.theme.spacing_sizes.s}px ${props.theme.spacing_sizes.m}px`, 
    // TODO: move colors
    color: props.disabled ? '#B0B5BA' : props.theme.palette.text.secondary, borderRadius: props.theme.shape.borderRadius, cursor: (props.disabled || props.selected) ? 'default' : 'pointer', userSelect: 'none' }, props.displayDelimiter && !props.selected && ({
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '1px',
        height: '16px',
        top: '25%',
        left: '-1px',
        backgroundColor: '#D7DBDF',
    },
})))));
const SegmentButton = ({ segment, selected, disabled, onClick, displayDelimiter = false, }) => {
    const theme = useTheme();
    const [{ textColor, backgroundColor, boxShadowColor }, api] = useSpring(() => ({
        backgroundColor: theme.customColors.button.pill.border,
        boxShadowColor: BOX_SHADOW_COLOR_DEFAULT,
        textColor: theme.palette.text.secondary,
        config: {
            mass: 1,
            tension: 711,
            friction: 40,
        }
    }), []);
    const handleClick = () => {
        if (!selected && !disabled) {
            onClick(segment.key);
            api.start({
                from: {
                    boxShadowColor: BOX_SHADOW_COLOR_DEFAULT,
                    backgroundColor: theme.customColors.button.pill.border,
                },
                to: {
                    boxShadowColor: BOX_SHADOW_COLOR_SELECTED,
                    backgroundColor: theme.customColors.button.onPrimary,
                },
            });
        }
    };
    const handleMouseEnter = () => {
        if (!selected && !disabled) {
            api.start({
                from: {
                    textColor: theme.palette.text.secondary,
                },
                to: {
                    textColor: theme.palette.text.primary,
                },
            });
        }
    };
    const handleMouseLeave = () => {
        if (!selected && !disabled) {
            api.start({
                from: {
                    textColor: theme.palette.text.primary,
                },
                to: {
                    textColor: theme.palette.text.secondary,
                },
            });
        }
    };
    useEffect(() => {
        if (!selected && !disabled) {
            api.start({
                to: {
                    boxShadowColor: BOX_SHADOW_COLOR_DEFAULT,
                    backgroundColor: theme.customColors.button.pill.border,
                    textColor: theme.palette.text.secondary,
                },
            });
        }
        else if (!disabled) {
            api.start({
                to: {
                    boxShadowColor: BOX_SHADOW_COLOR_SELECTED,
                    backgroundColor: theme.customColors.button.onPrimary,
                    textColor: theme.palette.text.primary,
                },
            });
        }
    }, [selected, api, theme, disabled]);
    return (_jsx(Button, { selected: selected, disabled: disabled, displayDelimiter: displayDelimiter, onClick: handleClick, style: {
            boxShadow: boxShadowColor.to((boxShadowVal) => `${BOX_SHADOW_SIZE} ${boxShadowVal}`),
            color: textColor.to((textColorVal) => textColorVal),
            backgroundColor: backgroundColor.to(backgroundColorVal => backgroundColorVal),
        }, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: typeof segment.displayValue === 'string' && (_jsx(BodySemiBold, { children: segment.displayValue })) }));
};
export default SegmentButton;
