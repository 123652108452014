import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import IconCustom from '@shared/ui/icons/Icon';
const Container = styled.div `
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    padding: 10px;
    border-radius: 99px;
    color: ${props => props.theme.palette.text.secondary};
    background-color: ${props => props.theme.customColors.menu.item.hover};

    &:hover {
        background-color: ${props => props.theme.customColors.menu.item.active};
    }

    &:focus, &:active {
        background-color: ${props => props.theme.customColors.menu.item.active};
        box-shadow: 0px 0px 0px 3px rgba(136, 144, 150, 0.10);
    }
`;
const CircleButton = ({ className = undefined, Icon, onClick = () => { }, }) => (_jsx(Container, { className: className, onClick: onClick, children: _jsx(IconCustom, { size: 20, IconComponent: Icon }) }));
export default CircleButton;
