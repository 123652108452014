import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerRow } from '@components/styled';
import SegmentButton from './SegmentButton';
const Container = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    padding: '4px',
    borderRadius: props.theme.shape.borderRadius,
    // TODO: move color
    backgroundColor: props.theme.customColors.button.pill.border,
}));
// NOTE: displaying delimiter only if its not the first item and not currently selected item
const shouldDisplayDelimiter = (segments, idx, currentSegmentKey) => idx !== 0 && segments[idx - 1].key !== currentSegmentKey;
const SegmentButtons = ({ className = undefined, currentSegmentKey, segments, onSelect, disabled = false, }) => (_jsx(Container, { className: className, children: segments.map((segment, idx) => (_jsx(SegmentButton, { segment: segment, selected: segment.key === currentSegmentKey, onClick: onSelect, displayDelimiter: shouldDisplayDelimiter(segments, idx, currentSegmentKey), disabled: disabled }, segment.key))) }));
export default SegmentButtons;
